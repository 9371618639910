article.cart {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

article.cart h1 {
  width: 100%;
}

article.cart h4 {
  width: 100%;
  text-align: center;
}

@media (min-width: 1024px) {
  article.cart {
    margin: 0 auto;
    flex-direction: row;
    justify-content: space-between;
    max-width: 1440px;
    margin-bottom: 6em;
  }
}
