section.cart-summary {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  flex-grow: 2;
  margin: 1em auto;
  background-color: var(--main-color);
  color: var(--light-color);
}

section.cart-summary h3 {
  width: 100%;
  display: flex;
  justify-content: space-around;
  font-size: 1.2em;
}

section.cart-summary h3 span {
  text-decoration: underline;
}

section.cart-summary h4.total-price {
  margin: 0.4em 0;
}

section.cart-summary a.go-to-order {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.6em;
  margin-top: 1em;
  width: 90%;
  background-color: var(--light-color);
  color: var(--main-color);
  font-size: 1em;
  text-decoration: none;
  text-align: center;
}

section.cart-summary a.go-to-order span {
  flex-grow: 1;
  text-align: center;
}

section.cart-summary a.go-to-order svg {
  margin-left: .2em;
}

section.cart-summary a.go-to-order:hover {
  color: var(--light-color);
  background-color: var(--main-color-hover);
}

@media (min-width: 1024px) {
  section.cart-summary {
    margin: 0 10px;
    flex-grow: 3;
    position: sticky;
    top: 70px;
    align-self: flex-start;
    height: auto;
  }

  section.cart-content {
    flex-grow: 7;
    max-width: 70%;
  }
}