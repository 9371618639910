aside.side-menu {
  min-width: 300px;
  background-color: var(--main-color);
  border-right: 2px solid var(--main-color);
}

aside.side-menu h2 {
  padding-top: 1em;
  color: white;
}

aside.side-menu button {
  display: none;
}

aside.side-menu ul {
  width: 100%;
  display: flex;
  flex-direction: column;
}

aside.side-menu ul li {
  width: 100%;
  display: block;
}

aside.side-menu ul li.category > a {
  padding: 1em;
  font-weight: bold;
  color: white;
}

aside.side-menu ul li.category > a:hover {
  background-color: white;
  color: var(--main-color);
}

aside.side-menu ul li.sublink a {
  padding: 10px 0 10px 2em;
}

aside.side-menu ul li a {
  display: block;
  text-decoration: none;
  font-size: 2rem;
  color: white;
}

aside.side-menu ul li a.active {
  background-color: white;
  color: var(--main-color);
}

aside.side-menu ul li.sublink a.active {
  background-color: var(--main-color);
  color: white;
  text-decoration: underline;
}
