button.cart-button {
  position: relative;
}

button.cart-button:hover {
  color: var(--main-color-hover);
}

button.cart-button div.cart-count {
  display: block;
  position: absolute;
  bottom: -5px;
  right: -5px;
  width: 20px;
  height: 20px;
  font-size: 1.6rem;
  font-weight: bold;
  text-align: center;
  line-height: 20px;
  color: var(--main-color);
  background-color: var(--light-color);
  border-radius: 50%;
  border: 1px solid var(--main-color);
  animation: show 0.3s 1 both;
}
