section.finish-order {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1em;
}

section.finish-order h1 {
  line-height: 1.2em;
}

section.finish-order p {
  text-align: center;
}

section.finish-order a {
  display: block;
  margin: 2em 0;
  padding: 1em 1.5em;
  background-color: var(--main-color);
  color: white;
  text-decoration: none;
  transition: 0.3s;
}

section.finish-order a:hover {
  background-color: var(--main-color-hover);
}