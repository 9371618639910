main.products aside {
  display: none;
}

section.products {
  padding: 0 10px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
}

section.products h1 {
  flex-basis: 100%;
  text-align: center;
}

section.products h2 {
  flex-basis: 100%;
  text-align: center;
}

section.products img.category-image {
  flex-basis: 90%;
  max-height: 300px;
  margin-top: 1em;
  object-fit: cover;
  align-self: center;
}

section.products div.product-card {
  position: relative;
  flex-basis: 90%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 2%;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid var(--main-color);
}

section.products div.product-card::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--main-color-transparent);
  opacity: 0;
  transition: 0.3s;
  z-index: 2;
}

section.products div.product-card:hover::after {
  opacity: 0.3;
}

section.products div.product-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

section.products div.product-card h4.name {
  text-align: center;
}

section.products div.product-card section.price-block {
  margin-top: auto;
  margin-bottom: auto;
}

section.products div.product-card section.price-block.in-promotion {
  display: flex;
  flex-direction: column;
  margin-bottom: unset;
}

section.products div.product-card section.price-block p.price {
  font-size: 2.6rem;
}

section.products div.product-card section.price-block.in-promotion p.price {
  font-weight: normal;
  text-decoration: line-through;
  font-size: 1.6rem;
  font-style: unset;
}


section.products div.product-card section.price-block p.price,
section.products div.product-card section.price-block.in-promotion p.promotion-price {
  padding: 5px;
  text-align: center;
  font-size: 2.6rem;
  font-weight: bold;
  font-style: italic;
  color: var(--main-color);
}

section.products div.product-card section.price-block.in-promotion p.promotion-price {
  font-size: 2.8rem;
  color: var(--error-color);
}

section.products div.product-card button {
  width: 100%;
  z-index: 4;
}

section.products div.pager {
  width: 100%;
  margin-bottom: 1em;
  display: flex;
  justify-content: center;
}

section.products div.pager ul {
  list-style: none;
  display: flex;
  align-items: center;
}

section.products div.pager ul li {
  display: none;
  padding: .2em .4em;
  font-size: 1.4em;
  cursor: pointer;
}

section.products div.pager ul li:first-child,
section.products div.pager ul li:last-child,
section.products div.pager ul li.previous-page,
section.products div.pager ul li.active+li {
  display: block;
}

section.products div.pager ul li.active {
  display: block;
  font-weight: bold;
  color: var(--main-color);
  font-size: 1.6em;
}

section.products div.pager ul li:hover {
  transform: scale(1.2);
}

section.products div.product-card button:hover {
  background-color: var(--main-color-hover);
}

@media (min-width: 560px) {
  section.products div.product-card {
    flex-basis: 15%;
    max-width: 250px;
    margin: 1%;
  }
}

@media (min-width: 1280px) {
  main.products {
    display: flex;
  }

  main.products aside {
    display: block;
  }

  section.products div.product-card {
    flex-basis: 15%;
    margin: 1%;
  }
}