div.navigation {
  height: 30vh;
}

div.logo {
  text-align: center;
}

div.logo img {
  height: calc(30vh - 60px);
}

nav {
  position: sticky;
  width: 100%;
  height: 60px;
  top: 0;
  left: 0;
  background-color: white;
  color: var(--main-color);
  display: flex;
  align-items: center;
  box-shadow: 0 3px 5px var(--main-color);
  z-index: 10;
}

nav svg {
  cursor: pointer;
}

nav button.open-menu {
  margin-left: 10px;
  padding: 0;
  color: var(--main-color);
  background-color: transparent;
}

nav button.close-menu {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 50px;
  height: 50px;
  padding: 0;
  text-align: center;
  line-height: 50px;
  color: var(--light-color);
  background-color: transparent;
}

nav button.expand-menu {
  position: absolute;
  padding: 0;
  top: 0;
  right: 50px;
  font-size: 1rem;
  color: white;
  background-color: transparent;
}

nav > ul {
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100vh;
  padding: 50px 0 10px;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  background-color: var(--dark-color);
  transition: 0.4s ease-out;
  z-index: 1;
}

nav ul.active {
  left: 0;
}

nav ul li {
  position: relative;
  margin-bottom: 20px;
}

nav ul li::after {
  content: "";
  position: absolute;
  bottom: 2px;
  left: 5%;
  width: 90%;
  margin: 0 auto;
  height: 2px;
  background-color: white;
}

nav ul li a {
  display: block;
  padding: 10px 20px;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  font-size: 2.4rem;
}

nav ul li:first-of-type a {
  text-transform: none;
}

nav ul li.category.expanded button {
  transform: rotate(180deg);
}

nav ul li.category ul {
  display: none;
  flex-direction: column;
  height: auto;
  transition: 0.3s;
}

nav ul li.category.expanded ul {
  display: block;
}

nav ul li.category.expanded ul li {
  margin: 0;
}

nav ul li.category.expanded ul li a {
  background-color: #fff;
  color: var(--dark-color);
}

nav button.cart-button {
  margin-left: auto;
  margin-right: 20px;
  padding: 0;
  color: var(--main-color);
  background-color: transparent;
  font-size: 1rem;
}

button.signin {
  margin-right: 10px;
  padding: 0;
  color: var(--main-color);
  background-color: transparent;
  font-size: 1rem;
}

@media (min-width: 1025px) {
  nav button.open-menu,
  nav ul button.close-menu {
    display: none;
  }

  nav ul {
    position: static;
    padding: 0;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
    background-color: transparent;
    height: 100%;
  }

  nav ul li {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    height: 100%;
  }

  nav ul li:after {
    display: none;
  }

  nav ul li img {
    height: 50px;
    width: auto;
  }

  nav ul li:nth-of-type(2) {
    margin-left: auto;
  }

  nav ul li:nth-last-child(1) {
    margin-right: auto;
  }

  nav ul li a {
    position: relative;
    height: 100%;
    font-size: 1.6rem;
    line-height: 6rem;
    padding: 0 1em;
    overflow: hidden;
    color: var(--dark-color);
  }

  nav ul li a:hover::after {
    content: "";
    position: absolute;
    bottom: 5px;
    width: 60%;
    height: 2px;
    background-color: var(--dark-color);
    animation: hoverLink 0.3s both;
  }

  nav ul li a.active::after {
    content: "";
    position: absolute;
    bottom: 5px;
    width: 60%;
    left: 20%;
    height: 2px;
    background-color: var(--dark-color);
    animation: none;
  }

  nav ul li.category {
    position: relative;
    display: flex;
    padding-right: 30px;
  }

  nav ul li.category ul {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 300px;
    opacity: 0;
    background-color: #fff;
    box-shadow: 0 3px 5px var(--main-color);
    transition: 0.3s;
    visibility: hidden;
  }

  nav ul li.category:hover ul {
    top: 100%;
    opacity: 1;
    visibility: visible;
  }

  nav ul li.category ul li {
    width: 100%;
    text-align: center;
  }

  nav ul li.category ul li a {
    width: 100%;
  }

  nav ul li.category button.expand-menu {
    position: static;
    color: var(--main-color);
    font-size: 0.6rem;
  }

  nav ul li.category:hover button.expand-menu {
    transform: rotate(180deg);
  }

  button.cart-button,
  button.signin {
    margin: none;
  }

  @keyframes hoverLink {
    0% {
      left: -90%;
    }

    70% {
      left: 30%;
    }

    100% {
      left: 20%;
    }
  }
}
