section.categories {
  margin: 20px auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  align-content: center;
}

section.categories h2,
section.products h2 {
  flex-basis: 100%;
}

section.categories a.card {
  align-self: stretch;
  position: relative;
  max-width: 400px;
  margin: 20px;
  text-decoration: none;
  overflow: hidden;
}

section.categories a.card::after {
  content: "Zobacz!";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 100px;
  background-color: var(--main-color-transparent);
  color: white;
  font-size: 6rem;
  text-align: center;
  opacity: 0;
  transition: 0.3s;
  z-index: 2;
}

section.categories a.card:hover::after {
  opacity: 1;
}

section.categories a.card div.name {
  width: 100%;
  padding: 20px 0;
  background-color: var(--main-color);
  color: white;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 4px;
  font-size: 2rem;
}

section.categories a.card img {
  display: block;
  margin: 10px 0 0;
  width: 100%;
  height: 300px;
  object-fit: cover;
}

@media (min-width: 1025px) {
  section.categories {
    flex-direction: row;
    justify-content: center;
  }

  section.categories a.card {
    flex-basis: 30%;
    margin: 20px 50px;
  }
}
section.package .wrapper {
  position: relative;
  margin: 20px;
  min-height: 500px;
}

section.package img {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  max-height: 400px;
  max-width: 500px;
  object-fit: cover;
}

section.package p {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  padding: 20px;
  font-size: 2.4rem;
  color: white;
  background-color: var(--main-color-transparent);
  text-align: center;
}

@media (min-width: 1025px) {
  section.package .wrapper {
    margin: 40px auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-shadow: unset;
  }

  section.package img {
    position: static;
    width: 45%;
    object-fit: cover;
  }

  section.package p {
    position: static;
    width: 45%;
    font-size: 3.6rem;
    text-align: left;
    background-color: unset;
    color: var(--main-color);
  }
}

section.products {
  text-align: center;
  font-size: 2rem;
}
