table{
  border-collapse: collapse;
}

table, th, td{
  border: 2px solid var(--main-color);
  text-align: center;
}

th, td{
  padding: .2em;
}

th{
  background-color: var(--main-color);
  color: var(--light-color);
}