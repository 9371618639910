div.app>footer {
  width: 100%;
  background-color: var(--secondary-color);
  color: white;
}

div.app>footer div.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

div.app>footer div.wrapper section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

div.app>footer div.wrapper section h3 {
  font-size: 2rem;
  letter-spacing: 4px;
  padding: 20px 0;
}

div.app>footer div.wrapper section a {
  display: block;
  padding: 10px 0;
  color: rgba(255, 2555, 255, 0.8);
  font-size: 1.4rem;
  text-decoration: none;
}

div.app>footer div.wrapper section a:hover {
  text-decoration: underline;
}

div.app>footer div.wrapper section.about div.socials {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

div.app>footer div.wrapper section.about div.socials a.social {
  display: block;
  width: 50px;
  height: 50px;
  text-align: center;
  cursor: pointer;
  transition: 0.4s;
  border-radius: 10px;
  overflow: hidden;
}

div.app>footer div.wrapper section.about div.socials a.social.facebook:hover {
  background-color: #3b5998;
}

div.app>footer div.wrapper section.about div.socials .social.instagram:hover {
  background-color: #fb3958;
}

div.app>footer div.author {
  /* margin-top: 30px; */
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  height: 70px;
  line-height: 70px;
  background-color: #111;
  font-size: 1.2rem;
}

div.app>footer div.author address {
  margin-left: 20px;
}

div.app>footer div.author a {
  color: white;
}

div.app>footer div.author p {
  margin: unset;
}

@media (min-width: 581px) {
  div.app>footer div.wrapper {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  div.app>footer div.wrapper section {
    margin-top: 2em;
    flex-basis: 50%;
  }
}

@media (min-width: 1025px) {
  div.app>footer div.wrapper {
    flex-wrap: nowrap;
  }

  div.app>footer div.wrapper section {
    margin-top: unset;
  }
}