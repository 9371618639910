article.order {
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  margin: 0 auto;
}

article.order section {
  margin-top: 1em;
}

article.order section p.error {
  display: block;
  margin: 0 1em 0.4em;
  font-weight: bold;
  font-size: 0.8em;
}

article.order section img {
  display: block;
  max-height: 60px;
}

article.order section.shipment section h2 {
  padding-left: 1em;
  text-align: left;
}

article.order section.shipment section form {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 90%;
  max-width: 600px;
}

article.order section.shipment section form label {
  display: flex;
  margin: 0.4em 0;
  padding: 0.2em 0.4em;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border: 2px solid var(--main-color);
}

article.order section.shipment section form label span {
  display: inline-block;
  margin: 0 auto 0 0.4em;
  padding-left: 0.4em;
  font-weight: bold;
}

article.order section.shipment section form label span span {
  color: #aaa;
}

article.order section.shipment section.recipient form label {
  position: relative;
  margin: 0.4em 0;
  padding: unset;
  border: none;
}

article.order section.shipment section.recipient form label input {
  width: 100%;
  padding-left: 0.4em;
  font-size: 1em;
  height: 3em;
}

article.order section.shipment section.recipient form label span {
  padding: 0.2em;
  position: absolute;
  top: 50%;
  left: 0.4em;
  transform: translateY(-50%);
  transition: 0.4s;
  background-color: #fff;
  color: #aaa;
}

article.order section.shipment section.recipient form label input:focus + span,
article.order
  section.shipment
  section.recipient
  form
  label
  input:not([value=""])
  + span {
  top: 0;
  font-size: 0.6em;
}

article.order section.shipment h2 {
  font-size: 1.4em;
}

article.order section.shipment section.options form label,
article.order section.shipment section.agreements form label {
  padding: 0.4em;
}

article.order section.shipment section.agreements {
  margin-bottom: 1em;
}

article.order section.shipment section.agreements strong {
  margin-top: 0.4em;
  display: inline-block;
  color: var(--error-color);
  font-weight: bold;
  font-size: 0.8em;
}

article.order section.shipment section.agreements form label span span {
  color: var(--error-color);
}

article.order section.summary h1 {
  font-size: 2.4rem;
}

article.order section.summary section {
  margin: 0.8em auto;
  padding: 0.4em;
  width: 90%;
}

article.order section.summary section h2 {
  text-align: left;
  font-size: 1.4em;
}

article.order section.summary section div {
  padding: 0.8em;
  border: 2px solid var(--main-color);
}

article.order section.summary section div p {
  display: block;
}

article.order section.summary section.delivery div,
article.order section.summary section.payment div {
  display: flex;
  padding: 0.8em;
  justify-content: space-between;
  align-items: center;
}

article.order section.summary button.back-to-shipment {
  display: block;
  margin: 0.8em auto;
  font-size: 0.8em;
}

article.order section.summary button.back-to-shipment:hover {
  background-color: var(--main-color-hover);
}

section.bill {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 90%;
  margin: 1em auto;
  background-color: var(--main-color);
  color: var(--light-color);
}

section.bill p,
section.bill h3 {
  padding: 0.2em 1em;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 1.2em;
}

section.bill h3 {
  font-size: 1.4em;
}

section.bill hr {
  margin: 1em 0;
  background-color: #fff;
  width: 90%;
  height: 2px;
}

section.bill button.next-stage {
  position: relative;
  padding: 0.6em;
  margin-top: 1em;
  width: 90%;
  background-color: var(--light-color);
  color: var(--main-color);
  font-size: 1em;
  text-decoration: none;
  text-align: center;
}

section.bill button.next-stage svg {
  position: absolute;
  right: 0.4em;
  top: 50%;
  transform: translateY(-50%);
}

section.bill button.next-stage:hover {
  color: var(--light-color);
  background-color: var(--main-color-hover);
}

@media (min-width: 421px) {
  article.order section.summary h1 {
    font-size: 3rem;
  }
}

@media (min-width: 1025px) {
  article.order {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  article.order section.bill {
    margin-top: 2em;
  }

  article.order section.shipment section form {
    margin: 0 0 0 2em;
  }

  section.bill {
    margin: 0 10px;
    max-width: 30%;
    position: sticky;
    top: 70px;
    align-self: flex-start;
    height: auto;
  }

  article.order section.summary h1 {
    font-size: 4rem;
  }
  article.order section.summary section {
    width: 100%;
  }
}
