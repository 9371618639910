div.modal-gray-background {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 20;
}

section.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1em;
  width: 600px;
  max-width: 90%;
  max-height: 600px;
  background-color: #fff;
  color: var(--dark-color);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 50;
}

section.modal header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

section.modal header h3 {
  padding: unset;
}

section.modal header button {
  margin-right: 0.4em;
}

section.modal header button:hover {
  background-color: var(--main-color-hover);
}

section.modal main {
  margin: 1em auto 2em;
  width: 90%;
  height: unset;
  display: flex;
  flex-direction: column;
}

section.modal main p {
  margin-bottom: 0.2em;
  font-size: 1em;
}

section.modal main p.ready-to-order {
  display: block;
  margin-top: 1em;
}

section.modal footer {
  display: flex;
  justify-content: space-around;
}

section.modal footer button {
  padding: 0.4em 1em;
  width: 10em;
  max-width: 46%;
  font-size: 16px;
  background-color: #fff;
  color: var(--dark-color);
}

section.modal footer button.close {
  border: 2px solid var(--main-color);
}

section.modal footer button.close:hover {
  background-color: var(--main-color);
  color: white;
}

section.modal button.ok:disabled,
section.modal button.ok:disabled:hover {
  color: white;
  background-color: rgb(82, 156, 82);
  border: 2px solid rgb(82, 156, 82);
  cursor: default;
}

section.modal footer button.ok {
  border: 2px solid green;
}

section.modal footer button.ok:hover {
  background-color: green;
  color: white;
}