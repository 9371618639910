section.stages-container {
  margin: 20px auto;
  display: flex;
  justify-content: center;
  align-items: stretch;
  width: 90%;
}

section.stages-container hr {
  margin: 20px 10px 0;
  height: 4px;
  max-width: 120px;
}

section.stages-container .stage {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  width: 100px;
  color: var(--main-color);
}

section.stages-container .stage span {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid var(--main-color);
  text-align: center;
  font-size: 3rem;
  transition: 0.3s;
}

section.stages-container div.stage.stage-completed {
  cursor: pointer;
}

section.stages-container div.stage.stage-completed:hover span {
  background-color: var(--main-color-hover);
}

section.stages-container div.stage.stage-completed:hover p {
  color: var(--main-color-hover);
}

section.stages-container .stage p {
  margin-top: 0.4em;
  font-size: 0.42em;
  text-align: center;
}

section.stages-container .stage-active {
  font-weight: bold;
}
section.stages-container .stage-active span {
  background-color: var(--dark-color);
  color: white;
  border-color: var(--dark-color);
}

section.stages-container .stage-active p {
  color: var(--dark-color);
}

section.stages-container .stage-completed span {
  background-color: var(--main-color);
  color: white;
}

@media (min-width: 831px) {
  section.stages-container .stage p {
    font-size: 0.6em;
  }
}

@media (min-width: 1025px) {
  section.stages-container {
    margin: 2em auto;
    max-width: 80%;
  }
  section.stages-container .stage p {
    font-size: 0.8em;
  }
}
