section.card-payment,
section.transfer-payment,
section.p24-payment {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

section.card-payment p {
  text-align: center;
  text-decoration: none;
}

section.p24-payment div,
section.card-payment div {
  padding: 0.6em 0.4em;
  margin: 1em auto 0.2em;
  width: 100%;
  font-size: 1.4em;
  border: 1px solid gray;
}

section.p24-payment div li {
  padding: 0.2em;
}
