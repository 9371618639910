div.app > main > header {
  position: relative;
  width: 100%;
  height: calc(100vh - 60px);
  background-image: url("../../images/header.jpg");
  background-position: center;
  background-size: cover;
}

div.app > main > header::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  animation: show 0.3s both;
}

header h1 {
  position: absolute;
  top: 10%;
  left: 50%;
  width: 90%;
  transform: translateX(-50%);
  color: var(--dark-color);
  text-align: center;
  letter-spacing: 10px;
  text-transform: uppercase;
  font-size: 3.4rem;
  animation: show 0.3s both;
  text-shadow: 0px 0px px rgba(0, 0, 0, 0.2);
}

header img {
  position: absolute;
  bottom: 4vh;
  left: 50%;
  animation: stamp 0.3s 0.3s both ease-in-out;
}

@media (min-width: 1025px) {
  header::before {
    background-color: rgba(255, 255, 255, 0.4);
  }

  header h1 {
    top: unset;
    top: 0;
    left: 20px;
    transform: unset;
    font-size: 8rem;
    letter-spacing: 10px;
    line-height: 2em;
    text-align: left;
  }

  header img {
    bottom: unset;
    left: unset;
    right: 20px;
    top: 20px;
  }
}

@keyframes stamp {
  0% {
    transform: translateX(-50%) scale(5);
    transform-origin: center;
    opacity: 0;
  }

  80% {
    transform: translateX(-50%) scale(1);
  }

  90% {
    transform: translateX(-50%) scale(1.1);
  }

  100% {
    transform: translateX(-50%) scale(1);
    opacity: 1;
  }
}
