div.size-panel {
  display: flex;
  align-items: center;
  margin: .2em 0 .4em;
}

select.size-select{
  margin-right: .6em;
  padding: .2em 0;
  width: 80px;
  text-align: center;
  font-size: 2rem;
}

span.size-info{
  color: var(--info-color);
  cursor: pointer;
}