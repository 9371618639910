ul.cart-list {
  flex-grow: 1;
  list-style: none;
  padding: 0 10px;
}

ul.cart-list li {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px 0;
  padding: 0.4em;
  border: 1px solid var(--main-color);
}

ul.cart-list li:first-of-type {
  margin-top: 0;
}

ul.cart-list li img {
  display: block;
  width: 100%;
  margin: 0 auto;
  object-fit: cover;
}

ul.cart-list li div.product-details {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

ul.cart-list li div.product-details a.product-name {
  width: 100%;
  text-align: left;
  font-size: 1.6em;
  color: var(--dark-color);
}

ul.cart-list li div.product-details p.price {
  display: block;
  margin: 10px 0;
  width: 100%;
  text-align: left;
  font-size: 1.4em;
}

ul.cart-list li div.order-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

ul.cart-list li div.order-details label {
  display: flex;
}

ul.cart-list li div.order-details label input {
  width: 50px;
  font-size: 1em;
  height: 3em;
  line-height: 2em;
  text-align: center;
  border: 2px solid var(--main-color);
}

ul.cart-list li div.order-details label button {
  padding: 0.4em;
  margin: unset;
}

ul.cart-list li div.order-details p.total {
  margin: 0 auto;
  font-size: 1.8em;
  color: var(--main-color);
  font-weight: bold;
}

ul.cart-list li div.order-details p.total.in-promotion {
  display: flex;
  flex-direction: column;
  align-items: center;
}

ul.cart-list li div.order-details p.total.in-promotion small {
  color: black;
  font-weight: normal;
  font-size: 0.6em;
  text-decoration: line-through;
}

ul.cart-list li div.order-details button.remove-item-button {
  margin-right: 0.4em;
  color: var(--main-color);
  background-color: transparent;
  font-size: 0.6em;
}

@media (min-width: 1024px) {
  ul.cart-list {
    margin: 0 auto;
  }

  ul.cart-list li {
    flex-direction: row;
    align-items: center;
  }

  ul.cart-list li img {
    max-width: 200px;
  }

  ul.cart-list li div.product-details {
    margin-left: 1em;
  }

  ul.cart-list li label.count-input {
    margin: 0 .6em;
  }

  ul.cart-list li div.order-details p.total {
    margin-right: 1em;
    width: 120px;
  }
}