* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --main-color: rgb(160, 88, 65);
  --main-color-hover: rgb(134, 75, 55);
  --main-color-transparent: rgba(160, 88, 65, 0.7);
  --secondary-color: rgb(18, 49, 50);
  --dark-color: rgb(17, 17, 17);
  --light-color: rgb(255, 255, 255);
  --filler-color: rgb(246, 243, 239);
  --error-color: #d9534f;
  --warning-color: #f0ad4e;
  --success-color: #5cb85c;
  --info-color: #5bc0de;
}

html {
  font-family: "Montserrat", Arial, sans-serif;
  font-size: 10px;
}

.wrapper {
  max-width: 1600px;
  margin: 0 auto;
}

section {
  animation: 0.7s 1 show;
}

a {
  transition: 0.3s;
}

button {
  background-color: var(--main-color);
  color: white;
  padding: 1em 1.2em;
  border: none;
  cursor: pointer;
  transition: 0.3s;
  font-family: "Montserrat", Arial, sans-serif;
}

button:focus {
  outline: none;
}

h1 {
  padding: 0.6em;
  color: var(--main-color);
  text-transform: uppercase;
  text-align: center;
  font-size: 4rem;
  letter-spacing: 6px;
}

h2 {
  padding: 0.6em;
  color: var(--main-color);
  text-transform: uppercase;
  text-align: center;
  font-size: 3rem;
  letter-spacing: 10px;
  line-height: 1.5em;
  font-weight: normal;
}

h3 {
  padding: 8px;
  font-size: 2.6rem;
  text-align: center;
}

h4 {
  padding: 5px;
  font-size: 2rem;
}

section {
  font-size: 2rem;
  line-height: 1.5em;
  max-width: 1600px;
  margin: 0 auto;
}

p {
  margin: 0.4em 0;
}

.error {
  color: var(--error-color);
}

hr {
  display: block;
  height: 5px;
  width: 60%;
  margin: 40px auto 20px;
  background-color: var(--main-color);
}

svg {
  pointer-events: none;
}

span.css-0 {
  animation: 0.7s show ease-in 1;
}

input {
  border: 2px solid var(--main-color);
}

input:focus,
input:active {
  border-color: var(--dark-color);
  outline: noen;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

pre {
  font-family: "Montserrat", Arial, sans-serif;
}

div.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex-grow: 1;
}

ol ol {
  margin-left: 1.5em;
}

@media (min-width: 1025px) {
  hr {
    width: 40%;
  }

  h1 {
    font-size: 5rem;
  }

  h2 {
    font-size: 4rem;
  }

  h3 {
    font-size: 3.2rem;
  }
}

@keyframes show {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}