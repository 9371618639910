@import "~react-image-gallery/styles/css/image-gallery.css";

article.product-details {
  width: 100%;
  font-size: 1em;
}

article.product-details button:hover {
  background-color: var(--main-color-hover);
}

article.product-details section.main-info {
  padding: 10px;
}

article.product-details section.main-info div.image-gallery button.image-gallery-play-button {
  display: none;
}

article.product-details section.main-info div.image-gallery button:hover {
  background-color: transparent;
  color: var(--main-color);
}

article.product-details section.main-info div.image-gallery button.image-gallery-thumbnail.active,
article.product-details section.main-info div.image-gallery button.image-gallery-thumbnail:hover {
  border: 4px solid var(--main-color);
}

article.product-details section.main-info div.details {
  margin-top: 20px;
  padding: 0 30px;
  font-size: 1em;
}

article.product-details section.main-info div.details h1 {
  line-height: 1.2em;
}

article.product-details section.main-info div.details p {
  margin: 1em 0;
  display: flex;
  justify-content: space-between;
}

article.product-details section.main-info div.details p.price {
  display: block;
  font-size: 2em;
  text-align: center;
}

article.product-details section.main-info div.details p.price.in-promotion small {
  margin-left: 0.2em;
  font-size: 0.6em;
  text-decoration: line-through;
}

article.product-details section.main-info div.details label.count-input {
  display: flex;
  margin-bottom: 1em;
  align-items: center;
  font-size: 1.2em;
}

article.product-details section.main-info div.details label.count-input input {
  max-width: 200px;
  line-height: 4rem;
  height: 6rem;
  font-size: 1em;
  text-align: center;
  border: 2px solid var(--main-color);
}

article.product-details section.main-info div.details label.count-input button {
  height: 6rem;
}

article.product-details section.main-info div.details button.add-to-checkout {
  width: 100%;
  padding: 20px;
  max-height: 100px;
  font-size: 1em;
}

article.product-details section.main-info div.details div.rate {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}

article.product-details section.main-info div.details div.rate .rate {
  margin: 0 0.1em;
  color: rgb(219, 186, 0);
}

article.product-details section.main-info div.details div.rate .rate:first-of-type {
  margin-left: 0.4em;
}

article.product-details section.main-info div.details div.rate button.rate {
  margin: 1em 1em 0;
  padding: 10px 25px;
  background-color: rgb(219, 186, 0);
  border: 2px solid rgb(219, 186, 0);
  color: white;
  font-size: 2rem;
  transition: 0.3s;
}

article.product-details section.main-info div.details div.rate button.rate:hover {
  color: rgb(219, 186, 0);
  background-color: #fff;
}

article.product-details section.main-info div.details button.share {
  padding: 0;
  background-color: transparent;
  color: var(--main-color);
}

article.product-details section.main-info div.details button.share:hover {
  color: var(--main-color-hover);
}

article.product-details section.description {
  padding: .4em;
}

article.product-details section.description h3 {
  color: var(--main-color);
}


article.product-details section.description pre {
  margin: 0 auto 0 0;
  width: 90%;
  font-size: 1em;
  padding: 0 1em;
  text-align: justify;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

@media (min-width: 1025px) {
  article.product-details section.main-info {
    display: flex;
    justify-content: center;
    padding: 1em;
  }

  article.product-details section.main-info div.image-gallery {
    flex-grow: 1;
    max-width: 520px;
    min-width: 400px;
  }

  article.product-details section.main-info div.details {
    margin: 0 1em;
    font-size: 2rem;
  }

  article.product-details section.main-info div.details p.price {
    display: block;
    margin-top: unset;
    padding: unset;
    font-size: 4rem;
    text-align: left;
  }

  article.product-details section.main-info div.details label.count-input {
    font-size: 2.4rem;
  }

  article.product-details section.main-info div.details label.count-input input {
    font-size: 2.4rem;
    height: 4.8rem;
  }

  article.product-details section.main-info div.details label.count-input button {
    padding: 0 2em;
    height: 4.8rem;
  }

  article.product-details section.main-info div.details div.rate button.rate {
    margin: 0 auto;
    font-size: 1em;
  }

  article.product-details section.description h3 {
    text-align: left;
  }

}